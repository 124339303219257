@value ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  z-index: 3;

  display: grid;

  overflow: hidden;

  padding: calc(unit * 2.5) calc(unit * 4);

  border-bottom: 1px solid var(--ring-line-color);
  background-color: var(--ring-content-background-color);

  grid-gap: calc(unit * 2);
  grid-template-columns: auto max-content;
  grid-template-rows: auto auto;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'heading actions';
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.heading {
  grid-area: heading;

  overflow: hidden;
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: end;
}

.title {
  composes: ellipsis;
}

.title.title {
  margin: 0;
}

.titleWrapper {
  display: flex;
  align-items: baseline;

  margin-bottom: unit;
}

.headerLink {
  padding: 0 calc(unit * 1.5);
}

.active {
  font-weight: bold;
}

.description {
  composes: ellipsis;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);

  & a {
    color: var(--ring-secondary-color);
  }
}

.vcsRoots {
  display: flex;
  align-items: baseline;
}

.additionalVcsRoots {
  margin-left: calc(unit / 2);
}

.numberErrors {
  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}

.warningMessage {
  display: flex;
  align-items: center;

  padding-top: calc(unit / 2);

  color: var(--ring-warning-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-smaller);
  fill: var(--ring-warning-color);

  & svg {
    width: 12px;

    margin-right: calc(unit / 2);
  }
}
