@value unit: var(--ring-unit);

.jobDetailsPopupTarget,
.jobDetailsSidebarTarget {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;

  & .content {
    overflow-y: auto;
  }
}

.jobDetailsPopupTarget .content {
  margin: 0 calc(unit * -4) calc(unit * -2);
  padding: 0 calc(unit * 4);
}

.jobDetailsSidebarTarget .content {
  margin: 0 calc(unit * -4) calc(unit * -2) calc(unit * -2.5);
  padding: 0 calc(unit * 4) 0 calc(unit * 2.5);
}
