@value unit: var(--ring-unit);

.button {
  &::before {
    position: absolute;
    top: unit;
    right: calc(unit * -4);
    bottom: 1px;
    left: calc(unit * -4);

    content: '';
  }

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.columnContent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: baseline;

  padding-top: 14px;
  padding-bottom: 6px;

  &::before {
    position: absolute;
    z-index: -1;
    top: unit;
    right: calc(unit * -4);
    bottom: 1px;
    left: calc(unit * -4);

    content: '';

    opacity: 1;
  }
}

.button:focus {
  outline: none;

  &::before {
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}

/* stylelint-disable selector-max-specificity */
.button:focus,
.item:hover .button,
.psevdoItem[data-hovered='true'] + .detailsHeading .button {
  @nest &::before {
    background-color: var(--ring-hover-background-color);
  }
}
/* stylelint-enable */

.build {
  cursor: default;
}

.item,
.detailsHeading,
.build,
.row {
  display: contents;
}

.psevdoItem {
  position: absolute;

  z-index: -1;
  top: calc(var(--absolute-child-top) + unit);
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: start / end;
  grid-row: var(--row) / span 2;
}

.psevdoItem[data-faded='true'] {
  /* stylelint-disable-next-line selector-max-specificity */
  & + .detailsHeading .columnContent > * {
    opacity: 0.25;
  }
}

.column {
  position: relative;

  height: calc(unit * 5);
  grid-column: start / end;
}

.branch,
.number,
.status {
  margin-right: unit;
}

.startDate {
  margin-left: auto;

  white-space: nowrap;
}
