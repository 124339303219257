@value ellipsis, font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  position: sticky;
  z-index: 3;
  top: 0;

  display: grid;
  overflow: hidden;

  box-sizing: border-box;
  height: calc(unit * 18);

  margin: calc(unit * -2) calc(unit * -4) 0;
  padding: calc(unit * 2.5) calc(unit * 4) calc(unit * 2);

  border-bottom: 1px solid var(--ring-line-color);

  background-color: var(--ring-content-background-color);
  grid-gap: calc(unit * 1.5);
  grid-template-columns: auto max-content;
  grid-template-rows: auto auto;
  grid-template-areas:
    'heading actions'
    'badges badges';
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.heading {
  grid-area: heading;

  overflow: hidden;
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: end;
}

.badges {
  grid-area: badges;

  display: flex;

  margin-top: calc(-0.5 * unit);
  column-gap: calc(unit / 2);

  & > div {
    max-width: 20%;
  }
}

.title {
  composes: ellipsis;
}

.button {
  margin-left: unit;
}
