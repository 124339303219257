@value unit: var(--ring-unit);

.links {
  position: relative;

  display: flex;
  gap: calc(unit * 2.5);

  padding: calc(unit * 0.75) 0;

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: calc(unit * -4);
    bottom: 1px;
    left: calc(unit * -4);

    content: '';

    background: var(--ring-sidebar-background-color);
  }
}

.linkButton {
  padding: 0;

  color: var(--ring-link-color);
}

.log {
  padding: unit calc(unit * 4) calc(unit * 2) 0;
}

.tabs {
  margin: unit 0 calc(unit * 1.5);
}

.buildPathContainer {
  display: flex;
  flex-direction: column;

  padding-bottom: unit;

  color: var(--ring-link-color);
}

.buildStatus {
  flex-shrink: 0;

  color: var(--ring-link-color);
}

.buildPathAndStatus {
  overflow: hidden;
  flex-wrap: nowrap;

  padding: unit 0;

  line-height: var(--ring-line-height);

  &:not(:last-child) {
    border-bottom: 1px solid var(--ring-line-color);
  }
}

.buildPath {
  overflow: hidden;

  white-space: nowrap;
}
