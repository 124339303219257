@value unit: var(--ring-unit);

.filter {
  display: flex;
  flex-direction: column;

  margin-bottom: calc(unit * 2);
}

.firstFiltersLine,
.seccondFiltersLine {
  display: flex;
  align-items: baseline;

  flex-direction: row;

  flex-wrap: wrap;
}

.stateButton.active .ignoredIcon,
.stateButton:hover .ignoredIcon,
.stateButton.active .mutedIcon,
.stateButton:hover .mutedIcon {
  color: var(--ring-text-color);
}

.stateButton.active .failedIcon,
.stateButton:hover .failedIcon {
  color: var(--ring-icon-error-color);
}

.stateButton.active .passedIcon,
.stateButton:hover .passedIcon {
  color: var(--ring-icon-success-color);
}

.stateIcon {
  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-color);
}

.filterLabel {
  margin-bottom: calc(unit * 2);
  margin-left: calc(unit / 2);
}

.count {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.viewSelect,
.statusFilter,
.totalDuration {
  margin-right: unit;
  margin-bottom: calc(unit * 2);
}

.testsSearch,
.filterOnlyNew,
.filterWithoutInvestigator {
  margin-right: calc(unit * 2);
  margin-bottom: calc(unit * 2);

  &:last-child {
    margin-right: 0;
  }
}

.scopeFilter {
  margin-bottom: calc(unit * 2);
}

.jobDetailsPopupTarget {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);
}

.jobDetailsSidebarTarget {
  margin: 0 calc(unit * -4) 0 calc(unit * -2.5);
  padding: 0 calc(unit * 4) 0 calc(unit * 2.5);
}

.jobDetailsPopupTarget,
.jobDetailsSidebarTarget {
  &.filter {
    flex-direction: row;
    flex-wrap: wrap;

    border-bottom: 1px solid var(--ring-line-color);
  }

  & .viewSelect,
  & .statusFilter,
  & .testsSearch,
  & .filterOnlyNew,
  & .filterWithoutInvestigator,
  & .scopeFilter {
    margin-bottom: calc(unit * 1.5);
  }

  & .seccondFiltersLine {
    flex: 1;

    min-width: 400px;
  }

  &.withOnlyNewCheckbox .seccondFiltersLine {
    min-width: 500px;
  }

  & .testsSearch {
    flex: 1;
  }
}
