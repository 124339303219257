@value unit: var(--ring-unit);

.link {
  display: flex;

  text-decoration: none !important;
}

.title {
  margin-left: calc(unit * 1.5);

  white-space: nowrap;

  color: var(--ring-white-text-color);

  font-weight: bold;
}
