@value unit: var(--ring-unit);

.textAreaWrapper {
  position: relative;
}

.textArea {
  min-height: calc(unit * 5.5);

  background-color: var(--ring-content-background-color);

  font-family: var(--ring-font-family-monospace);
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: calc(1.5 * unit);
}

.file {
  display: flex;
  align-items: end;
  flex-direction: row;
  column-gap: unit;
}

.actions {
  display: flex;
  align-items: end;
  flex-direction: row;
  column-gap: calc(1.5 * unit);
}

.trash {
  padding: 0;
}

.notice {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
