@value unit: var(--ring-unit);

.info {
  flex-grow: 1;

  padding: unit calc(unit * 2);

  &.pageTarget,
  &.jobDetailsPopupTarget {
    padding-right: calc(unit * 4);
    padding-left: calc(unit * 4);
  }

  &.jobDetailsSidebarTarget {
    padding-right: calc(unit * 4);
    padding-left: calc(unit * 2.5);
  }

  &.jobDetailsPopupTarget,
  &.jobDetailsSidebarTarget {
    background: var(--ring-sidebar-background-color);
    box-shadow: 0 -1px 0 0 var(--ring-line-color);
  }
}

.infoIcon {
  margin-right: unit;
  margin-left: 1px;
}
