@value unit: var(--ring-unit);

.dropdown {
  margin-left: calc(unit / 2);

  vertical-align: -3px;
}

.customLinkIcon {
  margin-right: unit;

  transition: color var(--ring-ease);

  color: var(--ring-icon-color);
}

.experimentIcon {
  composes: customLinkIcon;

  margin: 0 6px 0 -2px;
}

.customLink:hover .customLinkIcon,
.customLink:focus .customLinkIcon {
  transition: none;

  color: var(--ring-border-hover-color);
}

.customLink:active .customLinkIcon {
  transition: none;

  color: var(--ring-main-color);
}

.customLink.plugin.plugin {
  padding: 0;
}

/* stylelint-disable selector-max-specificity */
.customLink.plugin.plugin:hover,
.customLink.plugin.plugin:focus {
  background: none;
}
/* stylelint-enable */
