.content {
  display: flex;
  flex-direction: column;
}

.inputTime {
  display: flex;

  align-items: baseline;

  height: calc(var(--ring-unit) * 3);

  line-height: var(--ring-line-height-lower);

  &::before {
    margin-right: calc(var(--ring-unit) / 2);

    content: 'at';
  }

  &::-webkit-calendar-picker-indicator,
  &::-webkit-inner-spin-button,
  &::-webkit-datetime-edit-ampm-field {
    display: none;
  }
}

.daysOfWeekCheckbox {
  padding-bottom: var(--ring-unit);

  line-height: var(--ring-font-size);
}

.timeSettings {
  display: flex;

  padding-top: calc(var(--ring-unit) * 3 / 2);

  color: red;
}

.selectTimeZoneWrapper {
  flex: 1;

  padding-left: calc(var(--ring-unit) * 2);
}

.selectTimeZone {
  display: table;

  width: 100%;

  table-layout: fixed;

  white-space: normal;
}

.buttonPanel {
  display: flex;
}

.buttonPanelDeleteButton {
  position: relative;

  flex: 1;

  margin-right: calc(var(--ring-unit) * -1);

  text-align: right;
}
