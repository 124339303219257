@value unit: var(--ring-unit);

.popup {
  color: var(--ring-text-color);
}

.useArtifacts {
  display: inline-block;

  padding: 0 unit;

  border-right: 1px solid var(--ring-line-color);

  line-height: calc(unit * 4);
}

.deleteLine {
  height: auto;
  padding: 0 9px;

  line-height: calc(unit * 4);
}
