@value unit: var(--ring-unit);

.wrapper {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  justify-content: space-between;

  padding: 0 calc(unit * 1.5) calc(unit - 2px) calc(unit * 4);
}

.title {
  margin-right: calc(unit * 2);

  letter-spacing: 1px;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.addPipelineButton {
  height: auto;
  padding: 0;

  line-height: var(--ring-line-height-lower);
}

.addPipelineButtonInnerContainer {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;

  height: var(--ring-line-height-lower);
}

.addPipelineButtonIcon {
  height: var(--ring-line-height-lowest);
  margin-right: calc(unit * 0.5);
}
