@value unit: var(--ring-unit);

.actions {
  display: flex;
  column-gap: calc(unit * 1.5);
}

.actionButton {
  padding: 0;
}

.settings {
  width: 100%;
}

.title {
  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);

  font-weight: 600;
}

.editSettingsForm {
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: calc(unit * 1.75);

  &.inline {
    flex-direction: row;
    column-gap: unit;

    & .actions {
      column-gap: calc(unit * 1.75);
    }
  }

  & .cancelButton {
    color: var(--ring-secondary-color);
  }
}

.deleteButton {
  margin-left: auto;
}
