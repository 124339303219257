@value unit: var(--ring-unit);

.addParameterButton {
  font-size: var(--ring-button-font-size);
}

.paraneterForm {
  padding-bottom: unit;
}

.helpText {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
