@value unit: var(--ring-unit);

.parameter {
  position: relative;

  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit * 1.5);

  line-height: calc(unit * 3);

  &:hover .parameterActions > * {
    opacity: 1;
  }

  .parameterName,
  .parameterValue {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.inputContainer {
  max-width: 50%;
  margin: 0 -2px;
}

.inputs {
  display: grid;

  grid-template-columns: minmax(auto, auto) 16px minmax(auto, auto);
}

.input {
  text-overflow: ellipsis;
}

.deleted,
.deleted .input {
  text-decoration: line-through;

  color: var(--ring-secondary-color);
  -webkit-text-fill-color: var(--ring-secondary-color);
}

.input:disabled {
  background-color: transparent;
}

.parameter:not(:focus-within) .input {
  border-color: transparent;
  background-color: transparent;
}

.parameter:focus-within .input {
  background-color: var(--ring-content-background-color);
}

.parameter:focus-within .parameterActions > * {
  visibility: hidden;
}
