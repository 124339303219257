@value unit: var(--ring-unit);

.labelCreate {
  visibility: hidden;

  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);
}

.labelPrivate {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.list {
  margin-left: calc(unit * -4);
}

.repositoryItem {
  display: flex;

  align-items: center;
}

.repositoryItemLogin {
  padding-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.repositoryItemPrivate {
  align-self: center;

  margin-left: unit;

  color: #85878f;
}

.searchInputHelpText {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.item {
  position: relative;
}

.itemHover .labelCreate {
  visibility: visible;
}

.image {
  position: absolute;
  top: 6px;
  left: calc(unit * 4);

  width: calc(unit * 3);
  height: calc(unit * 3);
  margin-right: unit;

  border-radius: var(--ring-border-radius);
}
