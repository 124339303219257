@value font-smaller from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  display: inline-block;

  min-width: 100%;
}

.entity {
  position: relative;

  margin: calc(unit * 2) 0;

  transition: all ease-in 0.3s;
}

@value holderWidth: calc(unit * 30);
@value holderPadding: calc(unit * 4);

.policy {
  display: flex;
  align-items: baseline;

  width: 100%;
  margin: 0 calc(unit * -4);

  padding: unit calc(unit * 4) 0;

  border-bottom: 1px solid var(--ring-line-color);
}

.holder {
  display: flex;

  align-content: baseline;
  flex-shrink: 0;
  flex-wrap: wrap;

  width: holderWidth;
  padding-right: holderPadding;
}

.listings {
  width: 100%;
}

.toggleInherited {
  position: relative;

  display: inline-block;

  margin-left: calc(unit * 2);
  padding-right: calc(unit * 2);
  padding-left: unit;

  cursor: pointer;

  border: none;

  &::before {
    position: absolute;
    top: 7px;

    right: 4px;

    display: block;
  }
}

.inheritedVisibleToggle {
  &::before {
    top: 6px;

    transform: rotate(45deg);
  }
}

.inheritedVisible {
  width: 100%;

  margin: 0 calc(unit * -4);

  padding: 0 calc(unit * 3) 0 calc(unit * 4);

  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 -2px rgba(0, 0, 0, 0.1);
}

.helpIcon {
  padding-left: calc(unit / 2);

  line-height: inherit;
}

.inheritedContainer {
  padding-bottom: unit;
}

.bodyWidthDialogPreventer {
  width: 100% !important;
}

.description {
  composes: font-smaller;

  max-width: calc(unit * 80);
  margin-top: 0;

  color: var(--ring-secondary-color);
}
