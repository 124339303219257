@value unit: var(--ring-unit);

.autocomplete {
  width: 100%;

  padding-bottom: calc(unit * 1.5);
}

.autocompleteButton {
  height: 30px;
}

.autocompleteItem {
  display: flex;

  fill: var(--ring-secondary-color);

  align-items: end;
}

.avaterImg {
  height: calc(unit * 2);
  margin-top: 2px;
  margin-right: unit;
  padding: 0;

  border-radius: var(--ring-border-radius);
}

.privateRepo {
  color: var(--ring-secondary-color);

  font-size: 12px;
}

.privateRepoIcon {
  padding: 0 calc(unit / 2);

  line-height: var(--ring-line-height-taller);
  fill: var(--ring-secondary-color);

  & svg {
    width: 12px;
    height: 15px;
  }
}
