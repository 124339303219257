@value unit: var(--ring-unit);

.pageWithSidebar {
  display: flex;
  flex: 1;

  margin: calc(unit * -2) calc(unit * -4) 0;
}

.main {
  display: flex;
  overflow: auto;

  flex: 1;

  flex-direction: column;
}

.content {
  display: flex;
  align-self: baseline;
  flex: 1 1;
  flex-basis: 0;
  flex-direction: column;

  box-sizing: border-box;
  min-width: 100%;
  min-height: 0;
}

.chart {
  padding: 0 calc(unit * 4);
}

.openSidebar {
  & .header {
    padding-right: calc(unit * 2.5);
  }

  & .toolbar,
  & .chart {
    padding-right: calc(unit * 2.5);
  }
}
