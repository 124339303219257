@value unit: var(--ring-unit);

.wrapper {
  position: relative;
  z-index: var(--ring-fixed-z-index);
}

.edges {
  position: relative;

  overflow: visible;

  margin: calc(unit * -4);

  pointer-events: none;
  fill: transparent;

  stroke: var(--ring-icon-color);
}

.hoverArea {
  pointer-events: stroke;
  stroke: transparent;
  stroke-width: calc(unit * 2);
}

.hoverArea:hover + * {
  stroke: var(--ring-border-hover-color);
  stroke-width: 2px;
}

.selected.selected {
  stroke: var(--ring-main-color);
  stroke-width: 2px;
}

.warning.warning {
  stroke: var(--ring-icon-warning-color);
  stroke-width: 2px;
}

.deleting.deleting {
  stroke: var(--ring-icon-error-color);
  stroke-width: 2px;
}

.transitive {
  stroke-dasharray: unit, unit;
}
