@value unit: var(--ring-unit);

.sidebar {
  display: flex;
  flex-direction: column;
}

.title {
  composes: wrapper from '../PipelinesSidebar/PipelinesSidebarTitle/PipelinesSidebarTitle.css';
  composes: title from '../PipelinesSidebar/PipelinesSidebarTitle/PipelinesSidebarTitle.css';
}

.header {
  padding: calc(unit * 1.5) calc(unit * 0.5) calc(unit * 1.5) calc(unit * 4 - 2px);
}

.searchWrapper {
  flex: 1;

  padding: 0;
}
