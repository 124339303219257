@value unit: var(--ring-unit);

.inputContainer {
  display: flex;
  align-items: end;
  flex-direction: row;
  column-gap: unit;
}

.input {
  background-color: var(--ring-content-background-color);
}

.input.multiline {
  display: block;
  overflow: auto;

  min-height: calc(unit * 8);
  max-height: calc(unit * 20.5);

  white-space: pre;
  overscroll-behavior: contain;
}

.monospace {
  font-family: var(--ring-font-family-monospace);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.toggleable {
  margin-top: unit;
}

.container {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.inline {
  display: flex;
  align-items: center;

  white-space: nowrap;
}

.inline .input {
  width: calc(100% - unit / 2);
  margin: -2px 0 -2px calc(unit / 2);
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
