@value unit: var(--ring-unit);

.page {
  display: flex;
  flex: 1;
  flex-direction: column;

  margin: calc(unit * -2) calc(unit * -4) 0;
  padding: calc(unit * 2) calc(unit * 4) 0;

  background-color: var(--ring-sidebar-background-color);
}

.content {
  overflow: auto;
  flex: 1;
}
