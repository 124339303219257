@value unit: var(--ring-unit);

.page {
  display: flex;
  align-items: center;

  flex-direction: column;

  & .pageHeading.pageHeading {
    margin-top: calc(unit * 10);

    font-size: calc(unit * 5);

    font-weight: 400;
    line-height: calc(unit * 5 + unit / 2);
  }
}

.card {
  display: flex;

  width: 100%;
  max-width: 920px;
  min-height: 240px;

  margin-top: calc(unit * 5);

  border-radius: 20px;
  background-color: var(--ring-sidebar-background-color);
}

.createPipeline {
  display: flex;

  flex-direction: column;

  padding: calc(unit * 5) calc(unit * 5 / 2) calc(unit * 5) calc(unit * 5);

  & .createPipelineHeading.createPipelineHeading {
    margin: 0;
    padding: 0;

    font-size: calc(unit * 3);

    font-weight: bold;
    line-height: calc(unit * 3 + unit / 2);
  }

  & .createPipelineDescription {
    margin: 0;
    margin-top: unit;
  }
}

.createPipelineButtonWrapper {
  margin-top: auto;
}

.createPipelineIcon {
  margin-right: calc(unit / 2);
}

.previewImage {
  display: flex;
  align-items: end;

  padding: unit calc(unit * 5) 0 calc(unit * 5 / 2);
}
