@value font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  overflow: hidden;

  margin: calc(unit * -2) calc(unit * -4) 0;
  padding: calc(unit * 2.5) calc(unit * 4) 0;
  padding-bottom: calc(unit * 2.5);

  border-bottom: 1px solid var(--ring-line-color);
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.heading {
  grid-area: heading;
}

.title.title {
  margin-bottom: unit;
}

.changeVCS {
  padding: 0 calc(unit * 1.5);

  color: var(--ring-secondary-color);
}

.vcs {
  display: flex;
  align-items: center;
}

.vcsIcon {
  display: flex;

  margin-right: calc(unit / 2);
}

.vcsText {
  composes: font-smaller;

  color: var(--ring-secondary-color);
}
