@value unit: var(--ring-unit);

.container {
  display: flex;
}

.trigger {
  display: flex;
  align-items: center;
}

.slider {
  margin-right: calc(unit * 1.5);
  margin-bottom: calc(unit / 2);
}

.hint {
  padding-top: calc(unit * 0.75);
  padding-bottom: calc(unit * 1.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: 400;
  line-height: var(--ring-line-height-lowest);
}

.parallelismCount {
  font-weight: 600;
}
